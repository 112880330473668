import {makeAutoObservable, configure} from "mobx";

configure({
    enforceActions: "never"
});

class global {
    parameterSetting = {
        "Time": {key: "time", name: "Zeit (s)"},
        "Amb pressure (Bar)": {key: "ambPressure", name: "Umgebungsdruck (bar)"},
        "Accel Ped. Pos. (%)": {key: "pedal", name: "Pedalstellung (%)"},
        "Battery volt. (V)": {key: "batteryVolt", name: "Batteriespannung (v)"},
        "Boost (Bar)": {key: "boost", name: "Ladedruck (bar)"},
        "Boost target (Bar)": {key: "boostTarget", name: "Ziel-Ladedruck (bar)"},
        "Coolant (*C)": {key: "coolant", name: "Kühlmittel (°C)"},
        "Exhaust flap status": {key: "exaustFlap", name: "Auspuffklappe"},
        "Exhaust temp post cat (calc) (*C)": {key: "exaustTempPostCat", name: "Abgas nach Kat (°C)"},
        "Gear (-)": {key: "gear", name: "Gang"},
        "IAT (*C)": {key: "iat", name: "Ansaugluft (°C)"},
        "Lambda bank 1 (AFR)": {key: "lambda1", name: "Lambdabank (AFR)"},
        "LTFT (%)": {key: "ltft", name: "LTFT (%)"},
        "MAF (g/s)": {key: "maf", name: "MAF (g/s)"},
        "O2 sensor post cat (V)": {key: "o2PostCat", name: "O₂ Sensor nach Kat (V)"},
        "O2 sensor pre cat (V)": {key: "o2PreCat", name: "O₂ Sensor vor Kat (V)"},
        "Oil pressure (Bar)": {key: "oilPressure", name: "Öldruck (bar)"},
        "Oil temp (*C)": {key: "oilTemp", name: "Öltemperatur (°C)"},
        "RPM (rpm)": {key: "rpm", name: "Drehzahl (rpm)"},
        "STFT 1 (%)": {key: "stft", name: "STFT (%)"},
        "Speed (km/h)": {key: "speed", name: "Geschwindigkeit (km/h)"},
        "Throttle Position (*)": {key: "throttle", name: "Drosselklappenstellung"},
        "Torque act. clutch (Nm)": {key: "torque", name: "Drehmoment (Kupplung, NM)"},
        "Transmission temp (*C)": {key: "transmissionTemp", name: "Getriebetemperatur (°C)"},
        "VANOS IN act. (Grad KW)": {key: "vanos", name: "Vanos Ein (Grad kW)"},
        "WGDC (%)": {key: "wgdc", name: "WGDC (%)"},
        "Boost setpoint factor": {key: "boostPointFactor", name: "Ladedruck-Sollwertfaktor"},
        "Compressor after PID (kW)": {key: "compressorAfterPid", name: "Kompressor nach PID (kW)"},
        "Compressor base (kW)": {key: "compressorBase", name: "Kompressorbasis (kW)"},
        "Cyl1 Timing Cor (*)": {key: "cylinderTiming1", name: "Zylinder Timing 1"},
        "Cyl2 Timing Cor (*)": {key: "cylinderTiming2", name: "Zylinder Timing 2"},
        "Cyl3 Timing Cor (*)": {key: "cylinderTiming3", name: "Zylinder Timing 3"},
        "Cyl4 Timing Cor (*)": {key: "cylinderTiming4", name: "Zylinder Timing 4"},
        "Cyl5 Timing Cor (*)": {key: "cylinderTiming5", name: "Zylinder Timing 5"},
        "Cyl6 Timing Cor (*)": {key: "cylinderTiming6", name: "Zylinder Timing 6"},
        "EGT Pre-turbo (*C)": {key: "egtPreTurbo", name: "Abgastemperatur vor Turbo (°C)"},
        "Fuel mass flow rate (kg/h)": {key: "fuelFlowRate", name: "Kraftstoffmassendurchsatz"},
        "Load req.": {key: "load", name: "Fahrzeuglast-Anforderung"},
        "RPM (Dynamics) (RPM)": {key: "rpmDynamics", name: "Dynamische Drehzahl (rpm)"},
        "TQ lim 1 (-)": {key: "tqLim1", name: "TQ Lim 1"},
        "Valve lift req. warm (mm)": {key: "valeLiftWarm", name: "Ventilhub-Anforderung (warm, mm)"},
        "MHD version 2.32 98G0B v1.60 stg 1 93_98": {key: "mhdVersion", name: "MHD Version" }
    }

    settings = {};
    carSettings = {};
    datalogs = [];

    data = [
        // {name: "", time: 5022.019, rpm: 2169, torque: -34},
        // {name: "", time: 5022.107, rpm: 2158, torque: -33},
        // {name: "", time: 5022.197, rpm: 2155, torque: -34},
        // {name: "", time: 5022.285, rpm: 2148, torque: -36},
        // {name: "", time: 5022.369, rpm: 2142, torque: -34},
        // {name: "", time: 5022.448, rpm: 2136, torque: -34},
        // {name: "", time: 5022.537, rpm: 2126, torque: -34},
        // {name: "", time: 5022.626, rpm: 2122, torque: -34},
        // {name: "", time: 5022.714, rpm: 2116, torque: -34},
        // {name: "", time: 5022.793, rpm: 2106, torque: -34},
        // {name: "", time: 5022.878, rpm: 2100, torque: -34},
        // {name: "", time: 5022.966, rpm: 2093, torque: -34},
        // {name: "", time: 5023.055, rpm: 2090, torque: -34},
        // {name: "", time: 5023.135, rpm: 2077, torque: -34},
        // {name: "", time: 5023.224, rpm: 2074, torque: -34},
        // {name: "", time: 5023.308, rpm: 2064, torque: -34},
        // {name: "", time: 5023.396, rpm: 2060, torque: -35},
        // {name: "", time: 5023.475, rpm: 2054, torque: -34},
        // {name: "", time: 5023.554, rpm: 2045, torque: -36},
        // {name: "", time: 5023.626, rpm: 2042, torque: -34},
        // {name: "", time: 5023.775, rpm: 2030, torque: -34},
        // {name: "", time: 5023.854, rpm: 2024, torque: -34},
        // {name: "", time: 5023.947, rpm: 2020, torque: -34},
        // {name: "", time: 5024.036, rpm: 2013, torque: -34},
        // {name: "", time: 5024.114, rpm: 2004, torque: -34},
        // {name: "", time: 5024.194, rpm: 2000, torque: -34},
        // {name: "", time: 5024.277, rpm: 1993, torque: -33},
        // {name: "", time: 5024.366, rpm: 1988, torque: -34},
        // {name: "", time: 5024.455, rpm: 1980, torque: -33},
        // {name: "", time: 5024.533, rpm: 1974, torque: -34},
        // {name: "", time: 5024.617, rpm: 1965, torque: -32},
        // {name: "", time: 5024.706, rpm: 1960, torque: -33},
        // {name: "", time: 5024.785, rpm: 1954, torque: -33},
        // {name: "", time: 5024.864, rpm: 1949, torque: -33},
        // {name: "", time: 5024.947, rpm: 1942, torque: -33},
        // {name: "", time: 5025.036, rpm: 1936, torque: -34},
        // {name: "", time: 5025.125, rpm: 1930, torque: -33},
        // {name: "", time: 5025.234, rpm: 1921, torque: -18},
        // {name: "", time: 5025.316, rpm: 1964, torque: 2},
        // {name: "", time: 5025.396, rpm: 1958, torque: 28},
        // {name: "", time: 5025.475, rpm: 1980, torque: 160},
        // {name: "", time: 5025.554, rpm: 1976, torque: 208},
        // {name: "", time: 5025.637, rpm: 1954, torque: 253},
        // {name: "", time: 5025.726, rpm: 2019, torque: 276},
        // {name: "", time: 5025.805, rpm: 2008, torque: 286},
        // {name: "", time: 5025.883, rpm: 2044, torque: 295},
        // {name: "", time: 5025.967, rpm: 2059, torque: 298},
        // {name: "", time: 5026.045, rpm: 2087, torque: 297},
        // {name: "", time: 5026.115, rpm: 2122, torque: 291},
        // {name: "", time: 5026.183, rpm: 2143, torque: 298},
        // {name: "", time: 5026.257, rpm: 2162, torque: 308},
        // {name: "", time: 5026.345, rpm: 2200, torque: 321},
        // {name: "", time: 5026.424, rpm: 2234, torque: 342},
        // {name: "", time: 5026.517, rpm: 2275, torque: 362},
        // {name: "", time: 5026.606, rpm: 2310, torque: 392},
        // {name: "", time: 5026.686, rpm: 2348, torque: 418},
        // {name: "", time: 5026.754, rpm: 2388, torque: 443},
        // {name: "", time: 5026.837, rpm: 2428, torque: 468},
        // {name: "", time: 5026.905, rpm: 2474, torque: 486},
        // {name: "", time: 5026.974, rpm: 2516, torque: 512},
        // {name: "", time: 5027.047, rpm: 2554, torque: 535},
        // {name: "", time: 5027.136, rpm: 2602, torque: 550},
        // {name: "", time: 5027.225, rpm: 2656, torque: 550},
        // {name: "", time: 5027.294, rpm: 2695, torque: 550},
        // {name: "", time: 5027.378, rpm: 2728, torque: 544},
        // {name: "", time: 5027.456, rpm: 2818, torque: 554},
        // {name: "", time: 5027.525, rpm: 2851, torque: 570},
        // {name: "", time: 5027.594, rpm: 2912, torque: 574},
        // {name: "", time: 5027.678, rpm: 2968, torque: 574},
        // {name: "", time: 5027.766, rpm: 2998, torque: 562},
        // {name: "", time: 5027.845, rpm: 3060, torque: 568},
        // {name: "", time: 5027.923, rpm: 3089, torque: 580},
        // {name: "", time: 5028.007, rpm: 3156, torque: 588},
        // {name: "", time: 5028.086, rpm: 3212, torque: 588},
        // {name: "", time: 5028.165, rpm: 3250, torque: 582},
        // {name: "", time: 5028.244, rpm: 3310, torque: 580},
        // {name: "", time: 5028.317, rpm: 3348, torque: 579},
        // {name: "", time: 5028.406, rpm: 3418, torque: 582},
        // {name: "", time: 5028.495, rpm: 3463, torque: 583},
        // {name: "", time: 5028.569, rpm: 3514, torque: 589},
        // {name: "", time: 5028.647, rpm: 3546, torque: 582},
        // {name: "", time: 5028.737, rpm: 3624, torque: 582},
        // {name: "", time: 5028.815, rpm: 3662, torque: 588},
        // {name: "", time: 5028.894, rpm: 3702, torque: 591},
        // {name: "", time: 5028.987, rpm: 3756, torque: 594},
        // {name: "", time: 5029.076, rpm: 3804, torque: 603},
        // {name: "", time: 5029.165, rpm: 3884, torque: 601},
        // {name: "", time: 5029.238, rpm: 3942, torque: 603},
        // {name: "", time: 5029.318, rpm: 3958, torque: 602},
        // {name: "", time: 5029.446, rpm: 4062, torque: 602},
        // {name: "", time: 5029.536, rpm: 4080, torque: 600},
        // {name: "", time: 5029.626, rpm: 4143, torque: 601},
        // {name: "", time: 5029.694, rpm: 4174, torque: 600},
        // {name: "", time: 5029.763, rpm: 4252, torque: 603},
        // {name: "", time: 5029.847, rpm: 4283, torque: 602},
        // {name: "", time: 5029.926, rpm: 4332, torque: 602},
        // {name: "", time: 5029.994, rpm: 4344, torque: 604},
        // {name: "", time: 5030.072, rpm: 4394, torque: 604},
        // {name: "", time: 5030.157, rpm: 4432, torque: 604},
        // {name: "", time: 5030.225, rpm: 4478, torque: 606},
        // {name: "", time: 5030.294, rpm: 4530, torque: 610},
        // {name: "", time: 5030.368, rpm: 4576, torque: 610},
        // {name: "", time: 5030.456, rpm: 4616, torque: 608},
        // {name: "", time: 5030.546, rpm: 4646, torque: 608},
        // {name: "", time: 5030.615, rpm: 4699, torque: 610},
        // {name: "", time: 5030.684, rpm: 4741, torque: 607},
        // {name: "", time: 5030.758, rpm: 4768, torque: 606},
        // {name: "", time: 5030.849, rpm: 4821, torque: 602},
        // {name: "", time: 5030.936, rpm: 4864, torque: 604},
        // {name: "", time: 5031.005, rpm: 4888, torque: 605},
        // {name: "", time: 5031.073, rpm: 4956, torque: 601},
        // {name: "", time: 5031.143, rpm: 5004, torque: 599},
        // {name: "", time: 5031.238, rpm: 5041, torque: 601},
        // {name: "", time: 5031.326, rpm: 5055, torque: 594},
        // {name: "", time: 5031.395, rpm: 5088, torque: 595},
        // {name: "", time: 5031.473, rpm: 5128, torque: 598},
        // {name: "", time: 5031.547, rpm: 5170, torque: 598},
        // {name: "", time: 5031.626, rpm: 5232, torque: 600},
        // {name: "", time: 5031.695, rpm: 5244, torque: 596},
        // {name: "", time: 5031.764, rpm: 5280, torque: 596},
        // {name: "", time: 5031.833, rpm: 5330, torque: 594},
        // {name: "", time: 5031.917, rpm: 5367, torque: 590},
        // {name: "", time: 5031.996, rpm: 5394, torque: 587},
        // {name: "", time: 5032.065, rpm: 5433, torque: 586},
        // {name: "", time: 5032.134, rpm: 5479, torque: 582},
        // {name: "", time: 5032.203, rpm: 5508, torque: 579},
        // {name: "", time: 5032.297, rpm: 5555, torque: 578},
        // {name: "", time: 5032.376, rpm: 5572, torque: 578},
        // {name: "", time: 5032.445, rpm: 5618, torque: 573},
        // {name: "", time: 5032.514, rpm: 5657, torque: 570},
        // {name: "", time: 5032.583, rpm: 5696, torque: 564},
        // {name: "", time: 5032.658, rpm: 5714, torque: 560},
        // {name: "", time: 5032.737, rpm: 5738, torque: 558},
        // {name: "", time: 5032.816, rpm: 5792, torque: 550},
        // {name: "", time: 5032.906, rpm: 5800, torque: 548},
        // {name: "", time: 5032.984, rpm: 5861, torque: 541},
        // {name: "", time: 5033.063, rpm: 5886, torque: 540},
        // {name: "", time: 5033.138, rpm: 5904, torque: 532},
        // {name: "", time: 5033.217, rpm: 5932, torque: 533},
        // {name: "", time: 5033.306, rpm: 5962, torque: 531},
        // {name: "", time: 5033.384, rpm: 6000, torque: 531},
        // {name: "", time: 5033.463, rpm: 6070, torque: 531},
        // {name: "", time: 5033.547, rpm: 6086, torque: 530},
        // {name: "", time: 5033.626, rpm: 6102, torque: 530},
        // {name: "", time: 5033.734, rpm: 6144, torque: 532},
        // {name: "", time: 5033.824, rpm: 6182, torque: 538},
        // {name: "", time: 5033.893, rpm: 6238, torque: 534},
        // {name: "", time: 5033.970, rpm: 6256, torque: 534},
        // {name: "", time: 5034.046, rpm: 6261, torque: 536},
        // {name: "", time: 5034.135, rpm: 6322, torque: 531},
        // {name: "", time: 5034.198, rpm: 6352, torque: 538},
        // {name: "", time: 5034.267, rpm: 6369, torque: 540},
        // {name: "", time: 5034.360, rpm: 6398, torque: 530},
        // {name: "", time: 5034.435, rpm: 6400, torque: 536},
        // {name: "", time: 5034.504, rpm: 6434, torque: 532},
        // {name: "", time: 5034.572, rpm: 6484, torque: 537},
        // {name: "", time: 5034.657, rpm: 6480, torque: 532},
        // {name: "", time: 5034.736, rpm: 6506, torque: 534},
        // {name: "", time: 5034.825, rpm: 6559, torque: 539},
        // {name: "", time: 5034.893, rpm: 6594, torque: 532},
        // {name: "", time: 5034.964, rpm: 6594, torque: 530},
        // {name: "", time: 5035.047, rpm: 6638, torque: 529},
        // {name: "", time: 5035.136, rpm: 6666, torque: 529},
        // {name: "", time: 5035.225, rpm: 6693, torque: 528},
        // {name: "", time: 5035.319, rpm: 6724, torque: 524},
        // {name: "", time: 5035.393, rpm: 6731, torque: 522},
        // {name: "", time: 5035.467, rpm: 6768, torque: 522},
        // {name: "", time: 5035.556, rpm: 6766, torque: 518},
        // {name: "", time: 5035.645, rpm: 6830, torque: 516},
        // {name: "", time: 5035.734, rpm: 6823, torque: 514},
        // {name: "", time: 5035.808, rpm: 6868, torque: 513},
        // {name: "", time: 5035.887, rpm: 6870, torque: 508},
        // {name: "", time: 5035.976, rpm: 6896, torque: 502},
        // {name: "", time: 5036.055, rpm: 6922, torque: 496},
        // {name: "", time: 5036.144, rpm: 6942, torque: 464},
        // {name: "", time: 5036.213, rpm: 6484, torque: -118},
        // {name: "", time: 5036.287, rpm: 6336, torque: -115},
        // {name: "", time: 5036.366, rpm: 6157, torque: -109},
        // {name: "", time: 5036.455, rpm: 5926, torque: -94},
        // {name: "", time: 5036.544, rpm: 5676, torque: -87},
        // {name: "", time: 5036.613, rpm: 5464, torque: -83},
        // {name: "", time: 5036.687, rpm: 5294, torque: -80},
        // {name: "", time: 5036.766, rpm: 5156, torque: -78},
        // {name: "", time: 5036.834, rpm: 5220, torque: -66},
        // {name: "", time: 5036.892, rpm: 5193, torque: -22},
        // {name: "", time: 5036.978, rpm: 5188, torque: -39},
        // {name: "", time: 5037.056, rpm: 5182, torque: -40},
        // {name: "", time: 5037.135, rpm: 5173, torque: -57},
        // {name: "", time: 5037.214, rpm: 5161, torque: -57},
        // {name: "", time: 5037.293, rpm: 5152, torque: -57},
        // {name: "", time: 5037.363, rpm: 5146, torque: -57},
        // {name: "", time: 5037.437, rpm: 5138, torque: -57},
        // {name: "", time: 5037.516, rpm: 5132, torque: -57},
        // {name: "", time: 5037.605, rpm: 5117, torque: -56},
        // {name: "", time: 5037.684, rpm: 5112, torque: -56},
        // {name: "", time: 5037.763, rpm: 5098, torque: -56},
        // {name: "", time: 5037.847, rpm: 5092, torque: -56},
        // {name: "", time: 5037.936, rpm: 5084, torque: -56},
        // {name: "", time: 5038.015, rpm: 5078, torque: -56},
        // {name: "", time: 5038.114, rpm: 5077, torque: -56},
        // {name: "", time: 5038.202, rpm: 5060, torque: -56},
        // {name: "", time: 5038.276, rpm: 5050, torque: -56},
        // {name: "", time: 5038.366, rpm: 5038, torque: -56},
        // {name: "", time: 5038.445, rpm: 5018, torque: -56},
        // {name: "", time: 5038.534, rpm: 5008, torque: -56},
        // {name: "", time: 5038.608, rpm: 5004, torque: -56},
        // {name: "", time: 5038.682, rpm: 5007, torque: -56},
        // {name: "", time: 5038.766, rpm: 4994, torque: -56},
        // {name: "", time: 5038.855, rpm: 4974, torque: -56},
        // {name: "", time: 5038.944, rpm: 4965, torque: -56},
        // {name: "", time: 5039.022, rpm: 4958, torque: -55},
        // {name: "", time: 5039.097, rpm: 4946, torque: -55},
        // {name: "", time: 5039.176, rpm: 4932, torque: -54},
        // {name: "", time: 5039.245, rpm: 4926, torque: -54},
        // {name: "", time: 5039.325, rpm: 4921, torque: -54},
        // {name: "", time: 5039.396, rpm: 4910, torque: -55},

        {name: "", time: 86.361, rpm: 2172, torque: -22, speed: 52, throttle: 0},
        {name: "", time: 86.450, rpm: 2178, torque: -32, speed: 52, throttle: 0},
        {name: "", time: 86.572, rpm: 2161, torque: -36, speed: 52, throttle: 0},
        {name: "", time: 86.662, rpm: 2162, torque: -36, speed: 52, throttle: 0},
        {name: "", time: 86.741, rpm: 2146, torque: -36, speed: 52, throttle: 0},
        {name: "", time: 86.820, rpm: 2146, torque: -36, speed: 52, throttle: 0},
        {name: "", time: 86.910, rpm: 2136, torque: -36, speed: 51, throttle: 0},
        {name: "", time: 86.989, rpm: 2129, torque: -36, speed: 51, throttle: 0},
        {name: "", time: 87.057, rpm: 2122, torque: -35, speed: 51, throttle: 0},
        {name: "", time: 87.126, rpm: 2118, torque: -35, speed: 51, throttle: 0},
        {name: "", time: 87.200, rpm: 2116, torque: -36, speed: 51, throttle: 0},
        {name: "", time: 87.291, rpm: 2103, torque: -35, speed: 51, throttle: 0},
        {name: "", time: 87.369, rpm: 2103, torque: -36, speed: 51, throttle: 0},
        {name: "", time: 87.467, rpm: 2092, torque: -36, speed: 50, throttle: 0},
        {name: "", time: 87.619, rpm: 2081, torque: -35, speed: 50, throttle: 0},
        {name: "", time: 87.711, rpm: 2074, torque: -37, speed: 50, throttle: 0},
        {name: "", time: 87.800, rpm: 2066, torque: -36, speed: 50, throttle: 0},
        {name: "", time: 87.869, rpm: 2058, torque: -36, speed: 50, throttle: 0},
        {name: "", time: 87.953, rpm: 2052, torque: -34, speed: 49, throttle: 0},
        {name: "", time: 88.045, rpm: 2048, torque: -36, speed: 49, throttle: 0},
        {name: "", time: 88.161, rpm: 2038, torque: -36, speed: 49, throttle: 0},
        {name: "", time: 88.320, rpm: 2024, torque: -36, speed: 49, throttle: 0},
        {name: "", time: 88.409, rpm: 2014, torque: -36, speed: 48, throttle: 0},
        {name: "", time: 88.489, rpm: 2010, torque: -36, speed: 48, throttle: 0},
        {name: "", time: 88.579, rpm: 2003, torque: -36, speed: 48, throttle: 0},
        {name: "", time: 88.690, rpm: 1992, torque: -36, speed: 48, throttle: 0},
        {name: "", time: 88.779, rpm: 1990, torque: -36, speed: 48, throttle: 0},
        {name: "", time: 88.869, rpm: 1980, torque: -35, speed: 48, throttle: 0},
        {name: "", time: 88.947, rpm: 1970, torque: -36, speed: 47, throttle: 0},
        {name: "", time: 89.032, rpm: 1968, torque: -34, speed: 47, throttle: 0},
        {name: "", time: 89.120, rpm: 1959, torque: -36, speed: 47, throttle: 0},
        {name: "", time: 89.199, rpm: 1954, torque: -36, speed: 47, throttle: 0},
        {name: "", time: 89.279, rpm: 1946, torque: -36, speed: 47, throttle: 0},
        {name: "", time: 89.362, rpm: 1940, torque: -35, speed: 47, throttle: 0},
        {name: "", time: 89.452, rpm: 1938, torque: -36, speed: 47, throttle: 0},
        {name: "", time: 89.541, rpm: 1923, torque: -36, speed: 46, throttle: 0},
        {name: "", time: 89.667, rpm: 1922, torque: -16, speed: 46, throttle: 80},
        {name: "", time: 89.757, rpm: 2040, torque: 126, speed: 46, throttle: 100},
        {name: "", time: 89.837, rpm: 1995, torque: 206, speed: 46, throttle: 100},
        {name: "", time: 89.922, rpm: 1976, torque: 242, speed: 47, throttle: 100},
        {name: "", time: 90.011, rpm: 2004, torque: 249, speed: 47, throttle: 100},
        {name: "", time: 90.090, rpm: 2036, torque: 270, speed: 48, throttle: 100},
        {name: "", time: 90.184, rpm: 2062, torque: 280, speed: 48, throttle: 100},
        {name: "", time: 90.268, rpm: 2090, torque: 292, speed: 49, throttle: 100},
        {name: "", time: 90.362, rpm: 2112, torque: 280, speed: 49, throttle: 100},
        {name: "", time: 90.450, rpm: 2148, torque: 286, speed: 50, throttle: 100},
        {name: "", time: 90.549, rpm: 2170, torque: 290, speed: 51, throttle: 100},
        {name: "", time: 90.659, rpm: 2216, torque: 304, speed: 52, throttle: 100},
        {name: "", time: 90.753, rpm: 2260, torque: 328, speed: 52, throttle: 100},
        {name: "", time: 90.841, rpm: 2296, torque: 349, speed: 53, throttle: 100},
        {name: "", time: 90.919, rpm: 2341, torque: 370, speed: 54, throttle: 100},
        {name: "", time: 91.008, rpm: 2366, torque: 396, speed: 55, throttle: 100},
        {name: "", time: 91.087, rpm: 2418, torque: 420, speed: 56, throttle: 100},
        {name: "", time: 91.172, rpm: 2452, torque: 440, speed: 57, throttle: 100},
        {name: "", time: 91.261, rpm: 2506, torque: 465, speed: 58, throttle: 100},
        {name: "", time: 91.350, rpm: 2564, torque: 496, speed: 59, throttle: 100},
        {name: "", time: 91.438, rpm: 2614, torque: 517, speed: 60, throttle: 100},
        {name: "", time: 91.519, rpm: 2654, torque: 534, speed: 61, throttle: 100},
        {name: "", time: 91.611, rpm: 2708, torque: 544, speed: 62, throttle: 100},
        {name: "", time: 91.721, rpm: 2787, torque: 562, speed: 64, throttle: 100},
        {name: "", time: 91.840, rpm: 2870, torque: 572, speed: 65, throttle: 100},
        {name: "", time: 91.928, rpm: 2916, torque: 584, speed: 67, throttle: 100},
        {name: "", time: 92.012, rpm: 2990, torque: 597, speed: 68, throttle: 100},
        {name: "", time: 92.101, rpm: 3050, torque: 598, speed: 69, throttle: 100},
        {name: "", time: 92.190, rpm: 3089, torque: 586, speed: 71, throttle: 100},
        {name: "", time: 92.279, rpm: 3136, torque: 582, speed: 72, throttle: 100},
        {name: "", time: 92.368, rpm: 3210, torque: 576, speed: 73, throttle: 100},
        {name: "", time: 92.448, rpm: 3266, torque: 570, speed: 75, throttle: 100},
        {name: "", time: 92.542, rpm: 3312, torque: 568, speed: 76, throttle: 100},
        {name: "", time: 92.668, rpm: 3396, torque: 568, speed: 77, throttle: 100},
        {name: "", time: 92.749, rpm: 3448, torque: 573, speed: 79, throttle: 100},
        {name: "", time: 92.838, rpm: 3505, torque: 580, speed: 80, throttle: 100},
        {name: "", time: 92.909, rpm: 3572, torque: 586, speed: 81, throttle: 100},
        {name: "", time: 93.002, rpm: 3609, torque: 588, speed: 83, throttle: 100},
        {name: "", time: 93.091, rpm: 3646, torque: 578, speed: 84, throttle: 100},
        {name: "", time: 93.181, rpm: 3719, torque: 582, speed: 85, throttle: 100},
        {name: "", time: 93.273, rpm: 3774, torque: 588, speed: 87, throttle: 100},
        {name: "", time: 93.388, rpm: 3822, torque: 590, speed: 88, throttle: 100},
        {name: "", time: 93.462, rpm: 3884, torque: 588, speed: 89, throttle: 100},
        {name: "", time: 93.541, rpm: 3920, torque: 588, speed: 90, throttle: 100},
        {name: "", time: 93.641, rpm: 3992, torque: 589, speed: 92, throttle: 100},
        {name: "", time: 93.729, rpm: 4042, torque: 589, speed: 93, throttle: 100},
        {name: "", time: 93.819, rpm: 4100, torque: 586, speed: 94, throttle: 100},
        {name: "", time: 93.913, rpm: 4131, torque: 584, speed: 95, throttle: 100},
        {name: "", time: 94.002, rpm: 4204, torque: 582, speed: 97, throttle: 100},
        {name: "", time: 94.090, rpm: 4234, torque: 580, speed: 98, throttle: 100},
        {name: "", time: 94.170, rpm: 4290, torque: 580, speed: 99, throttle: 100},
        {name: "", time: 94.249, rpm: 4328, torque: 578, speed: 100, throttle: 100},
        {name: "", time: 94.328, rpm: 4374, torque: 578, speed: 101, throttle: 100},
        {name: "", time: 94.422, rpm: 4426, torque: 576, speed: 102, throttle: 100},
        {name: "", time: 94.500, rpm: 4480, torque: 574, speed: 103, throttle: 100},
        {name: "", time: 94.589, rpm: 4537, torque: 578, speed: 104, throttle: 100},
        {name: "", time: 94.683, rpm: 4578, torque: 577, speed: 105, throttle: 100},
        {name: "", time: 94.803, rpm: 4627, torque: 572, speed: 106, throttle: 100},
        {name: "", time: 94.911, rpm: 4664, torque: 572, speed: 108, throttle: 100},
        {name: "", time: 95.000, rpm: 4720, torque: 568, speed: 109, throttle: 100},
        {name: "", time: 95.088, rpm: 4786, torque: 568, speed: 110, throttle: 100},
        {name: "", time: 95.172, rpm: 4822, torque: 565, speed: 111, throttle: 100},
        {name: "", time: 95.262, rpm: 4852, torque: 562, speed: 112, throttle: 100},
        {name: "", time: 95.350, rpm: 4900, torque: 567, speed: 113, throttle: 100},
        {name: "", time: 95.439, rpm: 4956, torque: 570, speed: 114, throttle: 100},
        {name: "", time: 95.507, rpm: 4991, torque: 574, speed: 115, throttle: 100},
        {name: "", time: 95.603, rpm: 5044, torque: 576, speed: 116, throttle: 100},
        {name: "", time: 95.743, rpm: 5088, torque: 576, speed: 118, throttle: 100},
        {name: "", time: 95.859, rpm: 5161, torque: 574, speed: 119, throttle: 100},
        {name: "", time: 95.949, rpm: 5212, torque: 574, speed: 120, throttle: 100},
        {name: "", time: 96.033, rpm: 5260, torque: 574, speed: 121, throttle: 100},
        {name: "", time: 96.121, rpm: 5292, torque: 570, speed: 123, throttle: 100},
        {name: "", time: 96.210, rpm: 5333, torque: 566, speed: 123, throttle: 100},
        {name: "", time: 96.300, rpm: 5382, torque: 564, speed: 124, throttle: 100},
        {name: "", time: 96.378, rpm: 5422, torque: 560, speed: 125, throttle: 100},
        {name: "", time: 96.463, rpm: 5436, torque: 558, speed: 126, throttle: 100},
        {name: "", time: 96.552, rpm: 5496, torque: 555, speed: 127, throttle: 100},
        {name: "", time: 96.640, rpm: 5536, torque: 552, speed: 128, throttle: 100},
        {name: "", time: 96.730, rpm: 5558, torque: 547, speed: 129, throttle: 100},
        {name: "", time: 96.819, rpm: 5614, torque: 540, speed: 130, throttle: 100},
        {name: "", time: 96.946, rpm: 5667, torque: 532, speed: 131, throttle: 100},
        {name: "", time: 97.021, rpm: 5718, torque: 526, speed: 132, throttle: 100},
        {name: "", time: 97.100, rpm: 5722, torque: 524, speed: 133, throttle: 100},
        {name: "", time: 97.189, rpm: 5775, torque: 522, speed: 134, throttle: 100},
        {name: "", time: 97.269, rpm: 5796, torque: 522, speed: 135, throttle: 100},
        {name: "", time: 97.363, rpm: 5834, torque: 521, speed: 136, throttle: 100},
        {name: "", time: 97.452, rpm: 5890, torque: 520, speed: 137, throttle: 100},
        {name: "", time: 97.530, rpm: 5920, torque: 520, speed: 138, throttle: 100},
        {name: "", time: 97.620, rpm: 5957, torque: 516, speed: 138, throttle: 100},
        {name: "", time: 97.699, rpm: 6004, torque: 515, speed: 139, throttle: 100},
        {name: "", time: 97.789, rpm: 6018, torque: 516, speed: 140, throttle: 100},
        {name: "", time: 97.911, rpm: 6064, torque: 514, speed: 141, throttle: 100},
        {name: "", time: 98.001, rpm: 6095, torque: 512, speed: 142, throttle: 100},
        {name: "", time: 98.129, rpm: 6150, torque: 510, speed: 143, throttle: 100},
        {name: "", time: 98.209, rpm: 6182, torque: 508, speed: 144, throttle: 100},
        {name: "", time: 98.303, rpm: 6222, torque: 512, speed: 145, throttle: 100},
        {name: "", time: 98.392, rpm: 6224, torque: 512, speed: 146, throttle: 100},
        {name: "", time: 98.481, rpm: 6254, torque: 510, speed: 146, throttle: 100},
        {name: "", time: 98.560, rpm: 6304, torque: 510, speed: 147, throttle: 100},
        {name: "", time: 98.649, rpm: 6320, torque: 512, speed: 148, throttle: 100},
        {name: "", time: 98.743, rpm: 6358, torque: 512, speed: 149, throttle: 100},
        {name: "", time: 98.869, rpm: 6432, torque: 511, speed: 150, throttle: 100},
        {name: "", time: 98.970, rpm: 6466, torque: 511, speed: 150, throttle: 100},
        {name: "", time: 99.059, rpm: 6493, torque: 511, speed: 151, throttle: 100},
        {name: "", time: 99.148, rpm: 6495, torque: 509, speed: 152, throttle: 100},
        {name: "", time: 99.233, rpm: 6527, torque: 510, speed: 153, throttle: 100},
        {name: "", time: 99.322, rpm: 6554, torque: 508, speed: 153, throttle: 100},
        {name: "", time: 99.410, rpm: 6611, torque: 508, speed: 154, throttle: 100},
        {name: "", time: 99.500, rpm: 6630, torque: 508, speed: 155, throttle: 100},
        {name: "", time: 99.578, rpm: 6668, torque: 506, speed: 155, throttle: 100},
        {name: "", time: 99.672, rpm: 6675, torque: 504, speed: 156, throttle: 100},
        {name: "", time: 99.763, rpm: 6690, torque: 504, speed: 157, throttle: 100},
        {name: "", time: 99.906, rpm: 6720, torque: 501, speed: 158, throttle: 100},
        {name: "", time: 99.996, rpm: 6793, torque: 500, speed: 159, throttle: 100},
        {name: "", time: 100.083, rpm: 6790, torque: 501, speed: 159, throttle: 100},
        {name: "", time: 100.172, rpm: 6806, torque: 495, speed: 160, throttle: 100},
        {name: "", time: 100.261, rpm: 6868, torque: 493, speed: 160, throttle: 100},
        {name: "", time: 100.350, rpm: 6884, torque: 486, speed: 161, throttle: 100},
        {name: "", time: 100.444, rpm: 6924, torque: 474, speed: 162, throttle: 100},
        {name: "", time: 100.533, rpm: 6908, torque: 470, speed: 162, throttle: 100},
        {name: "", time: 100.622, rpm: 6958, torque: 456, speed: 163, throttle: 92.5},
        {name: "", time: 100.710, rpm: 6686, torque: -120, speed: 163, throttle: 0},
        {name: "", time: 100.811, rpm: 6445, torque: -114, speed: 164, throttle: 0},
        {name: "", time: 100.899, rpm: 6281, torque: -98, speed: 164, throttle: 0},
        {name: "", time: 100.988, rpm: 6009, torque: -86, speed: 163, throttle: 0},
        {name: "", time: 101.072, rpm: 5818, torque: -78, speed: 163, throttle: 0},
        {name: "", time: 101.161, rpm: 5608, torque: -72, speed: 163, throttle: 0},
        {name: "", time: 101.239, rpm: 5398, torque: -68, speed: 163, throttle: 0},
        {name: "", time: 101.330, rpm: 5220, torque: -64, speed: 163, throttle: 0},
        {name: "", time: 101.419, rpm: 5136, torque: -63, speed: 162, throttle: 0},
        {name: "", time: 101.508, rpm: 5141, torque: -62, speed: 162, throttle: 0},
        {name: "", time: 101.587, rpm: 5136, torque: -57, speed: 162, throttle: 0},
        {name: "", time: 101.681, rpm: 5142, torque: -26, speed: 162, throttle: 0},
        {name: "", time: 101.796, rpm: 5132, torque: -40, speed: 161, throttle: 0},
        {name: "", time: 101.899, rpm: 5120, torque: -40, speed: 161, throttle: 0},
        {name: "", time: 102.023, rpm: 5106, torque: -58, speed: 161, throttle: 0},
        {name: "", time: 102.112, rpm: 5100, torque: -58, speed: 160, throttle: 0},
        {name: "", time: 102.201, rpm: 5084, torque: -58, speed: 160, throttle: 0},
        {name: "", time: 102.279, rpm: 5077, torque: -58, speed: 159, throttle: 0},
        {name: "", time: 102.368, rpm: 5060, torque: -57, speed: 159, throttle: 0},
        {name: "", time: 102.447, rpm: 5052, torque: -57, speed: 159, throttle: 0},
        {name: "", time: 102.522, rpm: 5048, torque: -58, speed: 159, throttle: 0},
        {name: "", time: 102.611, rpm: 5038, torque: -57, speed: 158, throttle: 0},
        {name: "", time: 102.700, rpm: 5027, torque: -57, speed: 158, throttle: 0},
        {name: "", time: 102.779, rpm: 5027, torque: -57, speed: 158, throttle: 0},
        {name: "", time: 102.878, rpm: 5024, torque: -57, speed: 158, throttle: 0},
        {name: "", time: 102.961, rpm: 5008, torque: -57, speed: 157, throttle: 0},
        {name: "", time: 103.092, rpm: 4991, torque: -56, speed: 157, throttle: 0},
        {name: "", time: 103.181, rpm: 4968, torque: -56, speed: 157, throttle: 0},
        {name: "", time: 103.270, rpm: 4970, torque: -56, speed: 156, throttle: 0},
        {name: "", time: 103.349, rpm: 4958, torque: -56, speed: 156, throttle: 0},
        {name: "", time: 103.448, rpm: 4966, torque: -56, speed: 156, throttle: 0},
        {name: "", time: 103.542, rpm: 4948, torque: -56, speed: 155, throttle: 0},
        {name: "", time: 103.621, rpm: 4934, torque: -56, speed: 155, throttle: 0},
    ]

    constructor() {
        makeAutoObservable(this);
    }
}

const data = new global();
// const data = window.data = new global();

export default data;