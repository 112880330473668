import axios from "axios";
import $, { data } from "jquery";
import {ma, dma, ema, sma, wma} from "moving-averages";

import global from "../components/Global";

//############################################################################# Get Datalogs

function getDatalogs() {
    const {parameterSetting} = global;

    try {
        axios
            .get("https://dyno.mycoding.de/static/php/getDatalogs.php")
            .then((response) => {
                global.datalogs = [];

                for (const log of response.data) {
                    const {parameters, data} = log;
                    const datalog = [];
    
                    for (const row of data) {
                        const newRow = {};
                        for (let i = 0; i < row.length; i++) {
                            const parameter = parameters[i] || "";
                            const key = parameterSetting[parameter.replace(/﻿/g, "")]?.key || parameter;
                            newRow[key] = (row[i] !== undefined) ? row[i] : "";
                        }
                        datalog.push(newRow);
                    }
    
                    global.datalogs.push({...log, data: datalog});
                }

                // console.log(JSON.parse(JSON.stringify(global.datalogs)));
            })
            .catch((error) => {
                throw error;
            });
    } catch (error) {
        console.error(error);
    }
}

//############################################################################# Dyno Calculation

const weight = 1600;
const gearRatio = 1.542;
const differentialRatio = 3.077;
// const wheelRadius = 0.2502;
const wheelRadius = 0.301;

const dynoFactor = 1.09;

function dynoCalculation(datalog = []) {

    datalog = datalog.filter(x => x.pedal >= 99 && x.torque > 0);
    // datalog = datalog.filter(x => x.rpm >= 1800 && x.rpm <= 7000);
    // datalog = datalog.filter(x => x.pedal <= 1 && x.torque < 0);

    //------------------------------------------------------

    let index = 0;

    for (const item of datalog) {
        const {rpm, torque, speed} = item;

        const time = item.time - datalog[0].time;
    
        // if (index === 0) {
        //     item.ps = 0;
        //     index ++;
        //     continue;
        // }

        if (index >= datalog.length) break;
    
        // const prevSpeedMs = (datalog[index - 1]?.speed || 0) / 3.6;
        // const prevTime = datalog[index - 1]?.time || 0;
        // const speedMs = speed / 3.6;
    
        // // const prevSpeedMs = calcSpeed(datalog[index - 1]?.rpm) / 3.6;
        // // const prevTime = datalog[index - 1]?.time || 0;
        // // const speedMs = calcSpeed(rpm) / 3.6;
    
        // const acceleration = (speedMs - prevSpeedMs) / (time - prevTime);
    
        // const kraft = weight * acceleration;
    
        // const kw = (kraft * speedMs) / 1000;
        // const nm = ((kraft * speedMs) * 9.55) / rpm;
    
        // item.nm = nm;
        // item.ps = kw * 1.36;
    
        //------------------------------------------------------
    
        // const speedMs = speed / 3.6;
        // const kraft = (gearRatio * differentialRatio * torque) / (2 * wheelRadius);
    
        // const kw = (kraft * speedMs) / 1000;
        // const nm = ((kraft * speedMs) * 9.55) / rpm;
    
        // item.nm = nm;
        // item.ps = kw * 1.36;
    
        // item.nm = torque;
        // item.ps = ((torque * rpm) / 9550) * 1.36;
    
        //------------------------------------------------------
        //------------------------------------------------------
        //------------------------------------------------------
        
        // const nextSpeedMs = calcSpeed(datalog[index + 1]?.rpm) / 3.6;
        // // const nextSpeedMs = (datalog[index + 1]?.speed) / 3.6;
        // const nextTime = (datalog[index + 1]?.time || 0) - datalog[0].time;
        // const speedMs = calcSpeed(rpm) / 3.6;
        // // const speedMs = speed / 3.6;

        // // console.log(item.speed, calcSpeed(rpm));
    
        // let diff = Math.pow(nextSpeedMs, 2) - Math.pow(speedMs, 2);
        // // if (diff >= 20) diff = (Math.pow(calcSpeed(datalog[index + 1]?.rpm) / 3.6, 2) - Math.pow(speedMs, 2));
    
        // const watt = (0.5 * weight * (diff)) / (nextTime - time);
        // const ps = (watt / 1000) * 1.36 * dynoFactor;
        
        // item.ps = ps;
        // item.nm = torque;
        // // item.nm = ps / ((2 * Math.PI * rpm) / 60);
        // // item.nm = 7127 * ps / rpm;
    
        //------------------------------------------------------
        //------------------------------------------------------
        //------------------------------------------------------

        // if (index > 0) {

        // const prevTime = (datalog[index - 1]?.time || 0) - datalog[0].time;
        // // const prevTime = datalog[index - 1]?.time || 0;
        // // const prevTime = datalog[0]?.time || 0;
        // const prevSpeed = calcSpeed(datalog[index - 1]?.rpm || 0) / 3.6;
        // // const prevSpeed = calcSpeed(datalog[0]?.rpm || 0) / 3.6;
        
        // // const v = speed / 3.6;
        // const v = calcSpeed(rpm) / 3.6;
        // // const a = (v - prevSpeed) / time;
        // // const a = (v - prevSpeed) / (time - prevTime);

        // const W = (0.5 * weight * (Math.pow(v, 2) - Math.pow(prevSpeed, 2)));
        // // const kw = (W / time) / 1000;
        // const kw = (W / (time - prevTime)) / 1000;

        // // item.speed2 = calcSpeed(rpm);
        // item.ps = kw * 1.36;
        // // item.nm = 7127 * (kw * 1.36) / rpm;
        // item.nm = torque;

        // }

        //------------------------------------------------------
        //------------------------------------------------------

        if (index > 0) {

        const cw = 0.30; // Luftwiderstand Koeffizient
        const A = 2.08; // Stirnfläche
        const p = 1.2041; // Luftdichte (Meerespiegel 20°)

        // Wheel
        const wheelSidewallHeight = (255 / 10) * (30 / 100);
        const wheelDiameter = (19 * 2.54) + (2 * wheelSidewallHeight);
        // const wheelCircumference = wheelDiameter * Math.PI;
        // const rollingCircumference = wheelCircumference * 0.97;
        const wheelRadius2 = wheelDiameter / 2;

        const rpmWheel = rpm / (gearRatio * differentialRatio);
        // const V = rpmWheel * wheelCircumference;
        // const V = (rpmWheel * wheelCircumference) / 60;

        // Prev
        // const prev_rpmWheel = datalog[index - 1].rpm / (gearRatio * differentialRatio);
        // const prev_V = prev_rpmWheel * wheelCircumference;
        const prev_time = datalog[index - 1].time - datalog[0].time;

        const V = calcSpeed(rpm) / 3.6;
        const prev_V = calcSpeed(datalog[index - 1].rpm) / 3.6;
        // const V = item.speed / 3.6;
        // const prev_V = datalog[index - 1].speed / 3.6;

        // Acceleration
        const a = (V - prev_V) / (time - prev_time);

        // Force
        const F_acc = weight * a;
        const F_drag = 0.5 * cw * A * p * Math.pow(V, 2);

        // const Cr = 8.4 / wheelRadius2;
        const Cr = wheelRadius / wheelRadius2;
        const Fg = weight * 9.81;
        const F_roll = Cr * Fg;

        const F_net = F_acc + (F_drag + F_roll);

        // Torque
        // const nmWheel = a * weight * wheelRadius2;
        // const nmEngine = nmWheel / (gearRatio * differentialRatio);
        // const nm = F_net * a;

        // Power
        const W = F_net * V;
        const PS = (W / 1000) * 1.36;

        item.nm = (PS * dynoFactor * 7162) / rpm;
        item.ps = PS * dynoFactor;
        // item.nm = torque;

        // console.log(F_acc , F_drag , F_roll);



        // const psDrag = ((((F_drag + F_roll) * V) / 1000) * 1.36);
        // const nmDrag = (psDrag * 735.5) / (2 * Math.PI * rpm);
        // const nm = torque - nmDrag;
        // item.nm = nm * 0.9;
        // item.ps = ((nm * rpm) / 7162) - psDrag;

        // console.log(time - prev_time);

        //------------------------------------------------------
        //------------------------------------------------------

        // Vehicle
        // const v = calcSpeed(rpm) / 1.597;
        // const prev_v = calcSpeed(datalog[index - 1].rpm) / 1.597;

        // // Constants
        // const FA = 22;
        // const COD = 0.34;
        // const F = 90;
        // const Bar = 30;
        // const TP = 30;
        // const Weight = 3800;
        
        // // Aero HP
        // const AD = 0.0228645 * Bar / (0.5555 * (F - 32) + 273.15);
        // const ADF = 1.07556 * AD * COD * FA;
        // // const VSS = v;
        // // const VSS = 76.31; // !!!
        // // const prev_VSS = 75.85; // !!!
        // const VSS = v; // !!!
        // const prev_VSS = prev_v; // !!!
        // const ps_aero = (ADF * Math.pow(VSS, 3)) / 375;

        // // Roll HP
        // const C1 = 0.034667 * (TP - 35) * (TP - 45) - 0.063333 * (TP - 20) * (TP - 45) + 0.032 * (TP - 20) * (TP - 35);
        // const C2 = 0.0000007704 * (TP - 35) * (TP - 45) - 0.000000833333 * (TP - 20) * (TP - 45) + 0.0000003148 * (TP - 20) * (TP - 35);
        // const RDF = C1 + C2 * Math.pow(VSS, 2.5);
        // const ps_roll = RDF * Weight / 1000 * VSS / 375;

        // // Acc HP
        // const prev_TT = 1.65; // !!!
        // const TT = 1.76; // !!!
        // const ps_acc = 0.5 * (Weight / 32)  * (Math.pow((5280 / 3600), 2)) * (Math.pow(VSS, 2) - Math.pow(prev_VSS, 2)) / (TT - prev_TT)  / 550;

        // const ps = ps_aero + ps_roll + ps_acc;
        // console.log(ps_acc);

        // item.ps = ps;


        }
        

    
        // item.nm = torque;
        // item.ps = kw * 1.36;
    
        index ++;
    }

    //------------------------------------------------------ Return Original

    // return datalog;

    //############################################################################# Remove

    datalog = datalog.filter(x => x.ps >= 50 && x.nm >= 50);

    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);
    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);
    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);

    // for (let i = 2; i < datalog.length; i += 3) datalog.splice(i, 1);
    // for (let i = 2; i < datalog.length; i += 3) datalog.splice(i, 1);
    // for (let i = 2; i < datalog.length; i += 3) datalog.splice(i, 1);

    // return datalog;

    //############################################################################# Moving Average

    // const movingAverageData = [];
    // const windowSize = 5;

    // // for (let i = 0; i <= datalog.length - windowSize; i++) {
    // for (let i = 2; i < datalog.length; i += windowSize) {
    //     const item = datalog[i];
    //     const average_nm = calcAverage(datalog, i, "nm", windowSize);
    //     const average_ps = calcAverage(datalog, i, "ps", windowSize);

    //     movingAverageData.push({
    //         ...item,
    //         nm: average_nm,
    //         ps: average_ps,
    //     });
    // }

    // const smoothingTimes = 2;
    // const smoothing = 1;
    // const span = 5;

    const smoothingTimes = 1;
    const smoothing = 0.7;
    const span = 6;

    for (let i = 0; i < smoothingTimes; i++) {
        const avarageData_nm = sma(datalog.map(x => x.nm), span, smoothing);
        const avarageData_ps = ema(datalog.map(x => x.ps), span, smoothing);
    
        for (const key in avarageData_nm) {
            if (key.match(/^\d+$/)) {
                const index = Number(key);
                datalog[index].nm = avarageData_nm[index];
            }
        }
    
        for (const key in avarageData_ps) {
            if (key.match(/^\d+$/)) {
                const index = Number(key);
                // datalog[index].ps = avarageData_ps[index];
                datalog[index].psmooth = avarageData_ps[index];
            }
        }
    }

    let avarageData_nm = sma(datalog.map(x => x.nm), 7, 1);
    let avarageData_ps = sma(datalog.map(x => x.ps), 7, 1);

    for (const key in avarageData_nm) {
        if (key.match(/^\d+$/)) {
            const index = Number(key);
            datalog[index].nm = avarageData_nm[index];
        }
    }

    for (const key in avarageData_ps) {
        if (key.match(/^\d+$/)) {
            const index = Number(key);
            // datalog[index].ps = avarageData_ps[index];
            datalog[index].psmooth = avarageData_ps[index];
        }
    }

    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);

    // avarageData_nm = sma(datalog.map(x => x.nm), 3, 1);
    // avarageData_ps = sma(datalog.map(x => x.ps), 3, 1);

    // for (const key in avarageData_nm) {
    //     if (key.match(/^\d+$/)) {
    //         const index = Number(key);
    //         datalog[index].nm = avarageData_nm[index];
    //     }
    // }

    // for (const key in avarageData_ps) {
    //     if (key.match(/^\d+$/)) {
    //         const index = Number(key);
    //         datalog[index].ps = avarageData_ps[index];
    //     }
    // }

    datalog = datalog.filter((x, i) => i > 2);

    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);
    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);
    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);
    // for (let i = 1; i < datalog.length; i += 2) datalog.splice(i, 1);

    console.log(datalog.length);

    return datalog;

    //############################################################################# MIddle

    const middle = [];
    // const middle = [...datalog];
    // const middle = movingAvg(datalog, 20);

    // const psAvg = caculateMovingAverage(datalog.map(x => x.ps), 15);

    // for (let i = 0; i < psAvg.length; i++) {
    //     datalog[i].ps = psAvg[i];
    // }

    // datalog = datalog.slice(0, psAvg.length - 1);

    // console.log(psAvg);

    // return datalog;

    let i = 0;

    while (i < datalog.length) {
        if (datalog[i - 1] && datalog[i + 1]) {
            const averagePS = (datalog[i].ps + datalog[i - 1].ps + datalog[i + 1].ps) / 3;
            const averageNm = (datalog[i].nm + datalog[i - 1].nm + datalog[i + 1].nm) / 3;
            const value = {...datalog[i]};
            value.ps = averagePS;
            value.nm = averageNm;
            middle.push(value);
            i += 2;

        // if (datalog[i - 2] && datalog[i + 2]) {
        //     const averagePS = (datalog[i].ps + datalog[i - 1].ps + datalog[i - 2].ps + datalog[i + 1].ps + datalog[i + 2].ps) / 5;
        //     const averageNm = (datalog[i].nm + datalog[i - 1].nm + datalog[i - 2].nm + datalog[i + 1].nm + datalog[i + 2].nm) / 5;
        //     const value = {...datalog[i]};
        //     value.ps = averagePS;
        //     value.nm = averageNm;
        //     middle.push(value);
        //     i += 4;

        } else {
            i ++;
        }
    }

    let sum = 0;

    for(let i = 0; i < middle.length; i++) {
        if (i === 0) continue;

        sum += 30;

        const time = datalog[i].time - datalog[0].time;
        const prev_time = datalog[i - 1].time - datalog[0].time;
        const diff = time - prev_time;

        sum += diff;

        if (sum >= 0.25) {
            sum = 0;
            middle.splice(i, 1);
        }
    }

    // console.log(middle.length);


    i = 0;
    const middle2 = [];

    while (i < middle.length) {
        if (middle[i - 1] && middle[i + 1]) {
            const averagePS = (middle[i].ps + middle[i - 1].ps + middle[i + 1].ps) / 3;
            const averageNm = (middle[i].nm + middle[i - 1].nm + middle[i + 1].nm) / 3;
            const value = {...middle[i + 1]};
            value.ps = averagePS;
            value.nm = averageNm;
            middle2.push(value);

            i += 2;
        } else {
            i ++;
        }
    }

    return middle;
    // return middle2;

    
    // for (let i = 0; i < middle.length; i++) {
    //     if (middle[i].time - middle[0].time >= 0.2) {

    //     }
    //     console.log(i, middle[i].time - middle[0].time);
    // }

    // return middle;
}

function calcSpeed(rpm = 0) {
    return (2 * wheelRadius * Math.PI * rpm * 60) / (gearRatio * differentialRatio * 1000);
}

function calcAverage(data, index, key, windowSize) {
    const subset = data.slice(index, index + windowSize);
    const sum = subset.reduce((a, b) => a + b[key], 0);
    const average = sum / windowSize;
    return average;
}

//############################################################################# Message

function msg(text = "", status = null) {
    const {message} = global;
    if (!text) return;

    global.message.status = status;
    global.message.text = text;

    $("#message").fadeIn(250);

    clearTimeout(message.timeout);
    
    message.timeout = setTimeout(() => { $("#message").fadeOut(250); }, 4000);
}

//############################################################################# Sorting

function sortTable() {
    const {profile} = global;

    profile.table.sort(sortingCompare);
    profile.tableOriginal.sort(sortingCompare);
}

function sortingCompare(indexPrev, indexNext) {
    const {sorting} = global;

    const invert = (sorting.direction.toUpperCase() === "AZ") ? 1 : -1;
    let prev = indexPrev[sorting.column];
    let next = indexNext[sorting.column];

    if (prev.match(/^-?\d+(\.\d*)?$/)) prev = Number(prev);
    if (next.match(/^-?\d+(\.\d*)?$/)) next = Number(next);
    if (typeof prev === "string") prev = prev.toLowerCase();
    if (typeof next === "string") next = next.toLowerCase();

    return (prev > next) ? 1 * invert : (prev < next) ? -1 * invert : 0;;
}

//############################################################################# Form

function showForm(action = null) {
    const {defaultForm, selectedItem} = global;
    
    if (action === "ADD") global.form = JSON.parse(JSON.stringify(defaultForm));
    if (action === "SET") {
        const newForm = JSON.parse(JSON.stringify(selectedItem));
        delete newForm.dateCreated;
        delete newForm.date;

        for (const key in newForm) {
            let value = newForm[key];
            if (value && (key === "price" || key === "soldPrice")) value = price2str(value);
            if (key.startsWith("date")) value = date2str(value);
            newForm[key] = value;
        }

        global.form = newForm;
    }


    global.formAction = action;
    $("#overlay-form").fadeIn(250);
    focus("#input-form-description");
}

function hideForm() {
    $("#overlay-form").fadeOut(250);
}

//############################################################################# Helper Functions

function str2date(str) {
    return str.replace(/^(\d{2})\.(\d{2})\.(\d{4})/, "$3-$2-$1");
}

function date2str(date) {
    return date.replace(/^(\d{4})-(\d{2})-(\d{2})/, "$3.$2.$1");
}

function price2str(price) {
    if (typeof price === "string") price = Number(price.replace(",", "."));
    return price.toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function str2price(str) {
    return Number(str.replace(/\./g, "").replace(",", "."));
}

function focus(element) {
    if (element) setTimeout(() => { document.querySelector(element).focus(); }, 1);
}

//############################################################################# Export

export {getDatalogs, dynoCalculation, sortTable, focus, msg, showForm, hideForm, price2str, str2price, str2date, date2str};