import React from "react";
import ReactDOM from "react-dom/client";

import Dyno from "./components/Dyno";

import "./fontawesome/pro6/css/all.min.css";
import "./style.css";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <>
        <Dyno/>
    </>
);