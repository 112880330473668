import React, {Component} from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import axios from "axios";
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend} from "recharts";
import $ from "jquery";

import global from "./Global";
import {getDatalogs, dynoCalculation} from "../js/functions";

class Header extends Component {

    localstate = observable({
        middle: [],
        sectionOpen: [true, false],
    });

    async uploadFiles(files) {
        const formData = new FormData();

        for (const file of files) formData.append("files[]", file);

        try {
            axios
                .post(
                    "https://dyno.mycoding.de/static/php/upload.php",
                    formData,
                    {
                        headers: {"Content-Type": "multipart/form-data"}
                    }
                )
                .then((response) => {
                    if (response.data === "success") {
                        getDatalogs();
                    } else {
                        throw response;
                    }
                })
                .catch((error) => {
                    throw error;
                });
        } catch (error) {
            console.error(error);
        }
    }

    selectDatalog(datalog) {
        this.localstate.middle = dynoCalculation(datalog.data);
    }

    toggleMenuSection(element) {
        $(element).next(".content").slideToggle(200);
    }
    
    componentDidMount() {
        getDatalogs();

        setTimeout(() => {
            this.selectDatalog(global.datalogs[0]);
        }, 200);
    }

    render() {
        const {datalogs} = global;
        const {middle} = this.localstate;

        return (
            <div className="flex-row">
                <div id="menu">
                    <div id="buttons">
                        <label htmlFor="input-upload" className="btn">
                            <i className="fal fa-upload"></i><span>Upload</span>
                        </label>
                    </div>
                    <section>
                        <div className="head" onClick={(e) => this.toggleMenuSection(e.target)}>
                            <i className="fal fa-square-list"></i>
                            <span>Logs</span>
                        </div>
                        <div className="content">
                            <ul>{
                                datalogs.map((x, i) => {
                                    return (
                                        <li key={i} onClick={() => this.selectDatalog(datalogs[i])}>
                                            <span>{x.name}</span>
                                            <small>{x.dateCreated.replace(/_(\d{2}:\d{2}).*/, " $1 Uhr")}</small>
                                        </li>
                                    );
                                })
                            }</ul>
                        </div>
                    </section>
                    <section>
                        <div className="head" onClick={(e) => this.toggleMenuSection(e.target)}>
                            <i className="fal fa-car"></i>
                            <span>Fahrzeug</span>
                        </div>
                        <div className="content">
                            {/* <ul>
                                <li>
                                    <span>Genauigkeit: {position?.coords?.accuracy || "--"}</span>
                                    <span>Höhe: {position?.coords?.altitude || "--"}</span>
                                    <span>Höhe (Genau.): {position?.coords?.altitudeAccuracy || "--"}</span>
                                    <span>Heading: {position?.coords?.heading || "--"}</span>
                                    <span>Breite: {position?.coords?.latitude || "--"}</span>
                                    <span>Länge: {position?.coords?.longitude || "--"}</span>
                                    <span>Speed: {position?.coords?.speed || "--"}</span>
                                    <span>Time: {position?.timestamp || "--"}</span>
                                </li>
                                <li>{ JSON.stringify(position.corrds) }</li>
                            </ul> */}
                        </div>
                    </section>
                </div>
                <div id="chart">
                    <LineChart width={1650} height={650} data={middle}>
                        <CartesianGrid stroke="#222"/>
                        <Legend verticalAlign="top"/>
                        <XAxis dataKey="rpm"/>
                        <YAxis dataKey="nm"/>
                        <YAxis dataKey="psmooth"/>
                        {/* <Line type="monotone" dataKey="rpm" stroke="#111" activeDot={{ r: 5 }} dot={false}/> */}
                        {/* <Line type="monotone" dataKey="throttle" stroke="lightgreen" activeDot={{ r: 5 }} dot={false}/> */}
                        {/* <Line type="monotone" dataKey="speed" stroke="blue" activeDot={{ r: 5 }} dot={false}/> */}
                        {/* <Line type="monotone" dataKey="fuelFlowRate" stroke="yellow" activeDot={{ r: 5 }} dot={false}/> */}
                        {/* <Line type="monotone" dataKey="pedal" stroke="purple" activeDot={{ r: 5 }} dot={false}/> */}
                        <Line type="monotone" dataKey="nm" stroke="orange" activeDot={{ r: 5 }} dot={false}/>
                        <Line type="monotone" dataKey="psmooth" stroke="lightblue" activeDot={{ r: 5 }} dot={false}/>
                        <Line type="monotone" dataKey="ps" stroke="red" activeDot={{ r: 5 }} dot={false}/>
                        <Tooltip/>
                    </LineChart>
                    <div style={{ display: "flex", gap: "1em" }}>
                        <p>{middle.map(x => x.psmooth).max().toFixed(0)} PS Smooth</p>
                        <p>{middle.map(x => x.ps).max().toFixed(0)} PS</p>
                        <p>{middle.map(x => x.nm).max().toFixed(0)} NM</p>
                    </div>
                </div>
                <input id="input-upload" type="file" name="files[]" accept=".csv" multiple hidden onChange={(e) => this.uploadFiles(e.target.files)}/>
            </div>
        );
    }
}

Array.prototype.max = function() {
    // return Math.max.apply(null, this);
    return Math.max.apply(null, this.filter(x => x));
};
  
Array.prototype.min = function() {
    return Math.min.apply(null, this);
};

export default observer(Header);